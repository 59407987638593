import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";

import backIcon from "../../assets/img/icons/atras-icon.svg";
import note from "../../assets/img/icons/ordSugess.svg";
import Alert from "../../assets/img/icons/Alert2.svg";

import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
import {
  convertMoneyFormat,
  loader,
  validateEmptyString,
} from "../../helpers/helpers";

import { OrdGenericTemplate } from "../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import ModalNew from "../Layouts/ModalNew";

import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";

const DetailEmployee = (props) => {
  const store = useSelector((state) => state);
  const eaccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  let showEmployeeDetails = props.showEmployeeDetails;
  const [commentsModal, setCommentsModal] = useState({
    show: false,
    comments: "",
    detail: false,
  });
  const { load: commentLoader, trigger: getCommentMethod } = useGetMethod();

  // const {results: listInfo, load: listInfoLoader, trigger: getListInfo }= useGetMethod();
  const { results: listInfo, trigger: getListInfo } = useGetMethod();
  const { trigger: getDetailNomina } = useGetMethod();
  const { load: sendCommentLoader, trigger: sendCommentMethod } =
    usePostMethod();

  useEffect(() => {
    getCommentMethod({
      url: "/payroll/adjustment-subscriptions/employee/",
      objFilters: {
        employee: showEmployeeDetails?.data?.candidate_id,
        id: showEmployeeDetails?.data?.id,
        entity_account: eaccount,
      },
      token: token,
      doAfterSuccess: (res) => {
        setCommentsModal({
          ...setCommentsModal,
          comments: res?.result?.comments,
          details:
            showEmployeeDetails?.propsData === "En revisión" ? false : true,
        });
      },
    });

    getDetailNomina({
      url: "/payroll/deductionsAccrued/",
      objFilters: {
        candidateId: showEmployeeDetails?.data?.candidate_id,
        payrollSheetId: props?.payrollSheet,
        eaccount: eaccount,
      },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eaccount]);

  const renderTooltipMessage = () => (
    <Tooltip>
      <span> Añadir comentario </span>
    </Tooltip>
  );

  const renderTooltip = () => (
    <Tooltip>
      <span className="text-secondary">
        {" "}
        {showEmployeeDetails?.data?.news_summary}{" "}
      </span>
    </Tooltip>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getListInfo({
          url: "/payroll/payroll-template-detail-news/",
          objFilters: {
            id: showEmployeeDetails?.data?.id,
            template_id: props?.payrollSheet,
            entity_account: eaccount,
          },
          token: store.loginReducer.Authorization,
        });

        if (!result.success) {
          customSwaltAlert({
            showCancelButton: false,
            icon: "warning",
            title: "Intenta de nuevo",
            text: result.message,
            confirmButtonText: "Aceptar",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [
    store.loginReducer.Authorization,
    getListInfo,
    props.payrollSheet,
    showEmployeeDetails?.data?.id,
    eaccount,
  ]);

  const goBackFunction = () => {
    props.setDetailShowEmployee({ ...showEmployeeDetails, show: false });
    props?.triggers((state) => state + 1);
  };

  const closeCommentModal = () => {
    setCommentsModal({ ...commentsModal, show: false });
  };

  const sendComment = () => {
    let validations = validateEmptyString([
      {
        field: commentsModal.comments,
        message: "El comentario es obligatorio",
      },
    ]);

    if (validations.valid) {
      sendCommentMethod({
        url: "/payroll/payrollSheet/",
        method: "PUT",
        body: {
          id: showEmployeeDetails?.data?.id,
          candidate_id: showEmployeeDetails?.data?.candidate_id,
          comment: commentsModal.comments,
        },
        token: token,
        succesAction: (info) => {
          closeCommentModal();
          customSwaltAlert({
            icon: "success",
            title: info.message,
            confirmButtonText: "Aceptar",
            showCancelButton: false,
          }).then(() => {
            props.comment(4, showEmployeeDetails?.data?.id);
          });
        },
        doAfterException: (error) => {
          customSwaltAlert({
            icon: "success",
            title: error.message,
            confirmButtonText: "Aceptar",
            showCancelButton: false,
          });
        },
      });
    }
  };

  return (
    <>
      {(commentLoader || sendCommentLoader) && loader}

      <ModalNew
        title="Comentarios"
        show={commentsModal.show}
        btnYesName={"Guardar"}
        btnNoName={"Cerrar"}
        size="400"
        btnYesEvent={sendComment}
        btnYesDisabled={commentsModal.details}
        onHide={() => closeCommentModal()}
        btnNoEvent={() => closeCommentModal()}
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>Comentarios de empleado </p>
        <textarea
          placeholder={"Escribir..."}
          id="txtArea"
          rows="10"
          cols="10"
          disabled={commentsModal.details}
          onChange={(e) =>
            setCommentsModal({ ...commentsModal, comments: e.target.value })
          }
          defaultValue={commentsModal.comments}
          style={{
            height: "4rem",
            borderRadius: "5px",
            border: "1.5px solid #7FADDE",
            padding: "0 0.05rem",
            width: "100%",
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew>
      <OrdGenericTemplate
        backIcon={backIcon}
        showBackArrow={true}
        backArrowAction={() => {
          goBackFunction();
        }}
        titleIcon={{
          icon: (
            <>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipMessage()}
              >
                <img
                  alt="goal"
                  src={note}
                  className={`align-self-center mx-2 ${tableStyles.svgFilterDarkBlue} cursorPointer`}
                  onClick={() => {
                    setCommentsModal({ ...commentsModal, show: true });
                  }}
                  height="30px"
                  width="30px"
                />
              </OverlayTrigger>
            </>
          ),
        }}
        title={showEmployeeDetails?.data?.employee}
        className="px-4 my-2 w-90"
        titleSize={12}
        style={{ height: "min-content" }}
        titleStyle={{ marginLeft: "1rem", fontSize: "32px" }}
      >
        <div style={{ marginLeft: "2rem" }}>
          <Row>
            <div className={`${tableStyles.OrdSlideButtonNom} py-4 pb-4`}>
              <Row>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <div className="d-flex">
                      <label className={`${tableStyles.crudModalLabel}`}>
                        Días laborados
                      </label>
                      {showEmployeeDetails?.data?.days_to_pay < 30 && (
                        <>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip()}
                          >
                            <img alt="" src={Alert} className="d-flex ml-2" />
                          </OverlayTrigger>
                        </>
                      )}
                    </div>

                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={showEmployeeDetails?.data?.days_to_pay}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>
                      Salario Base
                    </label>
                    {/* aquí */}
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={convertMoneyFormat(listInfo?.base_salary_accrued)}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>
                      Salario contractual
                    </label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={convertMoneyFormat(
                        showEmployeeDetails?.data?.base_salary
                          ? showEmployeeDetails?.data?.base_salary
                          : "0"
                      )}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>
                      Devengado
                    </label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={convertMoneyFormat(
                        showEmployeeDetails?.data?.total_accrued
                          ? showEmployeeDetails?.data?.total_accrued
                          : "0"
                      )}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>
                      Deducciones
                    </label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={convertMoneyFormat(
                        showEmployeeDetails?.data?.total_deductions
                          ? showEmployeeDetails?.data?.total_deductions
                          : "0"
                      )}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>
                      Valor final a pagar
                    </label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={convertMoneyFormat(
                        showEmployeeDetails?.data?.total_payment
                          ? showEmployeeDetails?.data?.total_payment
                          : "0"
                      )}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <Col xs={6} className={"py-2"}>
              <b
                className={`${tableStyles.darkBlueText} ${tableStyles.f14} my-4 text-center mt-5`}
              >
                Devengados
              </b>
              <ul>
                {listInfo?.accrued?.length > 0 &&
                  listInfo?.accrued?.map((accrued, index) => (
                    <React.Fragment key={index}>
                      <li>
                        <Row className="d-flex justify-content-between">
                          <Col
                            className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}
                            xs={6}
                          >
                            <span style={{ whiteSpace: "nowrap" }}>
                              {accrued.name_att}
                            </span>
                          </Col>
                          <Col
                            className={`${tableStyles.f13} ${tableStyles.tlnTextGray} text-end`}
                            xs={6}
                          >
                            {convertMoneyFormat(accrued.value)}
                          </Col>
                        </Row>
                      </li>
                      <hr className="m-0"></hr>
                    </React.Fragment>
                  ))}
              </ul>
            </Col>
            <Col
              xs={6}
              style={{
                borderLeft: "1px solid #005dbf",
                backgroundColor: "#F2F7FC",
                borderRadius: "0px 6px 6px 0px",
              }}
              className={"py-2"}
            >
              <b
                className={`${tableStyles.darkBlueText} ${tableStyles.f14} my-4 text-center mt-5`}
              >
                Deducciones
              </b>
              <ul>
                {listInfo?.deductions?.length > 0 &&
                  listInfo?.deductions?.map((deduction, index) => (
                    <React.Fragment key={index}>
                      <li>
                        <Row className="d-flex justify-content-between">
                          <Col
                            className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}
                            xs={6}
                          >
                            <span style={{ whiteSpace: "nowrap" }}>
                              {deduction.name_att}
                            </span>
                          </Col>
                          <Col
                            className={`${tableStyles.f13} ${tableStyles.tlnTextGray} text-end`}
                            xs={6}
                          >
                            {convertMoneyFormat(deduction.value)}
                          </Col>
                        </Row>
                      </li>
                      <hr className="m-0"></hr>
                    </React.Fragment>
                  ))}
              </ul>
            </Col>
          </Row>
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default DetailEmployee;
